import React, {useEffect, useState} from 'react';
import footer from '../../assets/footer-analisys.png';


const QualityAnalysis = ({user}) => {

        return <div style={{
            paddingTop: 32,
            paddingLeft: 64,
            paddingRight: 64,
        }}>
            {user.backendUrl.indexOf('sandbox') === -1 &&
              <iframe style={{
                  backgroundColor: '#F1F5F4',
                  border: 'none',
                  borderRadius: 2,
                  boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)',
                  width: '93vw',
                  height: '75vh'
              }}
                      src="https://charts.mongodb.com/charts-project-0-ezbkl/embed/dashboards?id=a79bdf40-31f8-47a5-be39-423ed38810bb&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=true&scalingWidth=fixed&scalingHeight=fixed"></iframe>}
            {user.backendUrl.indexOf('sandbox') !== -1 && <iframe style={{
                backgroundColor: '#F1F5F4',
                border: 'none',
                borderRadius: 2,
                boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)',
                width: '93vw',
                height: '75vh'
            }}
                                                                  src="https://charts.mongodb.com/charts-project-0-ezbkl/embed/dashboards?id=b00b1ce6-41da-47d6-958c-ea94963acd5c&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=true&scalingWidth=fixed&scalingHeight=fixed"></iframe>}
            <div style={{
                display: 'flex',
                justifyContent: 'left',
                width: 'auto',
                height: 90,
                textAlign: 'center'
            }}>
                <img src={footer} style={{ height: 80 }}/>
            </div>

        </div>
};

export default QualityAnalysis;
