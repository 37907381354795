import React, {useEffect, useState} from 'react';
import footer from '../../assets/footer-processor.jpg';
import footerSr from '../../assets/footer-sr.jpg';
import {formatHistory} from "../../utils";
import {useHistory} from "../../contexts/HistoryProvider";
import HistoryContainer from "./history/HistoryContainer";
import HistoryTable from "./history/HistoryTable";

const History = () => {
    const {history, refreshHistory} = useHistory()
    const [tableData, setTableData] = useState(null)

    useEffect(() => {
        refreshHistory()
    }, [refreshHistory])

    useEffect(() => {
        if (history) {
            setTableData(formatHistory(history))
        }
    }, [history])


    return (
      <>

            <HistoryContainer>
                {tableData && <HistoryTable isAnalytics={false} tableData={tableData}/>}
            </HistoryContainer>
        <div style={{
          position: 'fixed',
          bottom: 10,
          left: 0,
          display: 'flex',
          paddingLeft: 64,
          justifyContent: 'left',
          width: 'auto',
          height: 80,
          textAlign: 'center',
        }}>
          <img src={footer} style={{ height: 80 }}/>
          <img src={footerSr} style={{ height: 80 }}/>
        </div>
      </>
    )
      ;
};

export default History;
