import React from 'react';
import { config } from '../../config';
import { useProcessingItems } from '../../contexts/ProcessingItemsContext';
import { theme } from '../../theme';
import ProcessorColumn from '../molecules/columns/ProcessorColumn';
import ProcessorCardFactory from '../molecules/factories/ProcessorCardFactory';
import Box from '../ui/Box/Box';
import footer from '../../assets/footer-processor.jpg'
import footerSr from '../../assets/footer-sr.jpg'
/**
 * This component wraps the UI behind the processing tab. It has a column for every processingItem
 * and returns Card components from its Factory
 * @returns {JSX.Element}
 * @constructor
 */
const Processors = () => {
  const { processingItems } = useProcessingItems();
  const steps = Object.values(config.processingSteps);

  return (
    <div>

  <Box style={{
      paddingLeft: theme.spacing['spacing-10'],
      paddingTop: theme.spacing['spacing-06'],
      height: window.innerHeight - 229,
      flexDirection: 'row',
      overflow: 'auto',
    }}>
      {/*{processingItems?.map((processingItem, i) => {*/}
      {/*  return (*/}
      {/*    <ProcessorColumn key={i} step={processingItem.step}>*/}
      {/*      <ProcessorCardFactory key={i} processingItem={processingItem}/>*/}
      {/*    </ProcessorColumn>);*/}
      {/*})}*/}

      {steps?.map((step, i) => {
        const stepItem = processingItems.find(item => item.step === step);
        console.log(stepItem);
        return (
          <ProcessorColumn key={i} step={step}>
            {stepItem && <ProcessorCardFactory key={i} processingItem={stepItem}/>}
          </ProcessorColumn>);
      })}
    </Box>
      <div style={{ display:'flex', paddingLeft: 64,justifyContent:'left', width:'auto',height:80, textAlign:'center'}}>
        <img src={footer} style={{ height: 80}}/>
        <img src={footerSr} style={{ height: 80}}/>
      </div>


    </div>
  );
};

export default Processors;
