import footer from '../../../assets/footer-processor.jpg';
import VStack from "../../ui/VStack";
import React, {useState} from "react";
import Title from "./Title";
import Profile from "./Profile";
import TabItems from "./TabItems";
import TabHeader from "./TabHeader";

const Tab = (props) => {
    const {data, initialTabIndex, title, hasProfile, hasPadding} = props
    const [tabIndex, setTabIndex] = useState(initialTabIndex || 0)

    return (
      <VStack>
        <TabHeader hasPadding={hasPadding}>
          <Title title={title}/>
          <TabItems {...props} setTabIndex={setTabIndex} tabIndex={tabIndex}/>
          <Profile hasProfile={hasProfile}/>
        </TabHeader>
        {data.contentItems[tabIndex]}
      </VStack>
    )
}

export default Tab
