import React from 'react';
import {theme} from "../../theme";
import VStack from "../ui/VStack";
import Modal from "../ui/Modal";

const TabLayout = ({children}) => {
    return (
        <>
            <Modal/>
            <VStack space={theme.spacing["spacing-07"]} style={{
                paddingTop: theme.spacing["spacing-11"],
            }}>
                  {children}
            </VStack>
        </>

    );
};

export default TabLayout;
